<template>
  <div class="p-4">
    <el-popover v-if="shareCard" trigger="click">
      <div class="w-64 flex flex-col">
        <div class="w-full" @click="wannaFriend">申请添加为好友</div>
      </div>
      <div slot="reference" class="rounded-lg mb-4 shadow-2xl hover:shadow-inner w-350px p-4 flex flex-wrap">
      <span><head-image :name="shareCard.nickName"
                        :url="shareCard.headImage"
                        :size="100"/></span>
        <div class="flex-1 flex flex-col justify-between text-xl">
          <div class="w-full px-2 text-blue-500">{{ shareCard.nickName }}</div>
        </div>
        <div class="text-sm text-gray-400 w-full pt-4 border-t-2 mt-4 border-gray-300">个人名片</div>
      </div>
    </el-popover>
    <div v-else>
      未知消息类型
    </div>
  </div>

</template>
<script>
import HeadImage from '@/components/common/HeadImage.vue'

export default {
  name: 'ChatShareFriend',
  components: { HeadImage },
  props: {
    msgInfo: {
      type: Object,
      required: true
    },
    chatInfo: {
      type: Object,
    }
  },
  data () {
    return {}
  },
  methods: {
    wannaFriend () {
      const that = this
      this.$prompt('请输入添加备注', '添加好友', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value})=>{
        that.$http({
          url: '/friend/add?friendId=' + that.shareCard.id + '&applyContent=' + value,
          method: 'POST'
        }).then(() => {
          that.$message.success('已发送申请，等待对方同意')
        })
      })
    },
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    shareCard () {
      try {
        return JSON.parse(this.msgInfo.content)
      } catch (e) {
        return null
      }
    }
  }
}
</script>
