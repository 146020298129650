<template>
  <div class="p-4 w-full flex justify-center">
    <div class="w-500px rounded-lg shadow-2xl p-2 hover:shadow-inner">
      <template v-if="applyObj">
        <div class="flex w-full flex-wrap">
          
          <div class="w-full text-dark-orange font-bold p-2">好友申请</div>
          <div class="flex flex-col items-center">
            <head-image :name="applyObj.nickName"
                        :url="applyObj.headImage"
                        :size="100"/>
          </div>
          <div class="flex-1 w-0 flex flex-col justify-between">
            
            <div class="px-2 text-blue-500">{{ applyObj.nickName }}</div>
            <div class="p-2">备注： {{ applyObj.applyContent || '未填写备注' }}</div>
            
            <div class="text-sm text-gray-400 pt-2 text-right w-full">
              {{ $date.toTimeText(applyObj.applyTime) }}
            </div>
          </div>
          
          <div class="w-full flex justify-around mt-4" v-if="applyObj.status===0">
            <el-button type="warning" @click="rejectApply(applyObj.id)">拒绝</el-button>
            <el-button type="primary" @click="agreeApply(applyObj.id)">通过</el-button>
          </div>
          <div class="border-gray-300 border-t-2 text-sm text-gray-400 w-full mt-4 text-center" v-if="applyObj.status===1">
            已通过
          </div>
          <div class="border-gray-300 border-t-2 text-sm text-gray-400 w-full mt-4 text-center" v-if="applyObj.status===2">
            已拒绝
          </div>
          <div class="border-gray-300 border-t-2 text-sm text-gray-400 w-full mt-4 text-center" v-if="applyObj.status===3">
            已过期
          </div>
        </div>
      
      </template>
      <div v-else>
        未知消息类型
      </div>
    </div>
  </div>
</template>
<script>
import HeadImage from '@/components/common/HeadImage.vue'

export default {
  name: 'ChatFriendApply',
  components: { HeadImage },
  props: {
    content: {
      type: String,
      required: true
    },
    msgId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
    agreeApply (friendId) {
      this.$http({
        url: '/friend/agree?friendId=' + friendId + '&msgId=' + this.msgId,
        method: 'POST'
      }).then(() => {
        this.$message.success('通过成功，对方已成为您的好友')
      }).finally(() => {
        this.$store.commit('refetchChatWith',[this, 0])
      })
    },
    rejectApply (friendId) {
      this.$http({
        url: '/friend/refund?friendId=' + friendId + '&msgId=' + this.msgId,
        method: 'DELETE'
      }).then(() => {
        this.$message.success('已拒绝')
      }).finally(() => {
        this.$store.commit('refetchChatWith',[this, 0])
      })
    }
  },
  computed: {
    applyObj () {
      try {
        return JSON.parse(this.content)
      } catch (e) {
        return null
      }
    }
  }
}
</script>
