import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=6a17c1d6"
import script from "./Chat.vue?vue&type=script&lang=js"
export * from "./Chat.vue?vue&type=script&lang=js"
import style0 from "./Chat.vue?vue&type=style&index=0&id=6a17c1d6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_ubrbtih2qfyokjyfwkrqqblaa4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports