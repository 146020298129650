<template>
	<div class="chat-msg-item">
		<div :class="`chat-msg-tip${msgInfo.type === $enums.MESSAGE_TYPE.RECALL?' text-center':''}`"
			v-if="msgInfo.type === $enums.MESSAGE_TYPE.RECALL || msgInfo.type == $enums.MESSAGE_TYPE.TIP_TEXT">
			{{ msgInfo.content }}
		</div>
		<div class="chat-msg-tip" v-if="msgInfo.type == $enums.MESSAGE_TYPE.TIP_TIME">
			{{ $date.toTimeText(msgInfo.sendTime) }}
		</div>
		<div class="chat-msg-normal" v-if="isNormal" :class="{ 'chat-msg-mine': mine }">
			<div class="head-image">
				<head-image :name="showName" :size="40" :url="headImage" :id="msgInfo.sendId"></head-image>
			</div>
			<div class="chat-msg-content">
				<div v-show="mode == 1 && msgInfo.groupId && !msgInfo.selfSend" class="chat-msg-top">
					<span>{{ showName }}</span>
				</div>
        <div v-if="msgInfo.type===$enums.MESSAGE_TYPE.SHARE_FRIEND" :class="`flex justify-${mine?'end':'start'}`">
          <chat-share-friend :msg-info="msgInfo" :chat-info="chatInfo"/>
        </div>
				<div v-show="mode == 2" class="chat-msg-top">
					<span>{{ showName }}</span>
					<span>{{ $date.toTimeText(msgInfo.sendTime) }}</span>
				</div>
				<div class="chat-msg-bottom" @contextmenu.prevent="showRightMenu($event)">
					<div ref="chatMsgBox">
						<span class="chat-msg-text" v-if="msgInfo.type == $enums.MESSAGE_TYPE.TEXT"
							v-html="$emo.transform(msgInfo.content)"></span>
						<div class="chat-msg-image" v-if="msgInfo.type == $enums.MESSAGE_TYPE.IMAGE">
							<div class="img-load-box" v-loading="loading" element-loading-text="上传中.."
								element-loading-background="rgba(0, 0, 0, 0.4)">
								<img class="send-image" :src="JSON.parse(msgInfo.content).thumbUrl"
									@click="showFullImageBox()" loading="lazy" />
							</div>
							<span title="发送失败" v-show="loadFail" @click="onSendFail"
								class="send-fail el-icon-warning"></span>
						</div>
						<div class="chat-msg-file" v-if="msgInfo.type == $enums.MESSAGE_TYPE.FILE">
							<div class="chat-file-box" v-loading="loading">
								<div class="chat-file-info">
									<el-link class="chat-file-name" :underline="true" target="_blank" type="primary"
										:href="data.url">{{ data.name }}</el-link>
									<div class="chat-file-size">{{ fileSize }}</div>
								</div>
								<div class="chat-file-icon">
									<span type="primary" class="el-icon-document"></span>
								</div>
							</div>
							<span title="发送失败" v-show="loadFail" @click="onSendFail"
								class="send-fail el-icon-warning"></span>
						</div>
					</div>
					<div class="chat-msg-voice" v-if="msgInfo.type == $enums.MESSAGE_TYPE.AUDIO" @click="onPlayVoice()">
						<audio controls :src="JSON.parse(msgInfo.content).url"></audio>
					</div>
					<div class="chat-action chat-msg-text" v-if="isAction && msgInfo.type!==$enums.MESSAGE_TYPE.SHARE_FRIEND">
						<span v-if="msgInfo.type==$enums.MESSAGE_TYPE.ACT_RT_VOICE" title="重新呼叫" @click="$emit('call')"
							class="iconfont icon-chat-voice"></span>
						<span v-if="msgInfo.type==$enums.MESSAGE_TYPE.ACT_RT_VIDEO" title="重新呼叫" @click="$emit('call')"
							class="iconfont icon-chat-video"></span>
						<span>{{msgInfo.content}}</span>
					</div>
					<div class="chat-msg-status" v-if="!isAction">
						<span class="chat-readed" v-show="msgInfo.selfSend && !msgInfo.groupId
						&& msgInfo.status == $enums.MESSAGE_STATUS.READED">已读</span>
						<span class="chat-unread" v-show="msgInfo.selfSend && !msgInfo.groupId
						&& msgInfo.status != $enums.MESSAGE_STATUS.READED">未读</span>
					</div>
					<div class="chat-receipt" v-show="msgInfo.receipt" @click="onShowReadedBox">
						<span v-if="msgInfo.receiptOk" class="icon iconfont icon-ok" title="全体已读"></span>
						<span v-else>{{msgInfo.readedCount}}人已读</span>
					</div>
				</div>
			</div>
		</div>
    <div>{{$enums.MESSAGE_STATUS.FRIEND_APPLY}}</div>
    <div v-if="msgInfo.type===$enums.MESSAGE_TYPE.FRIEND_APPLY">
      <chat-friend-apply :content="msgInfo.content" :msg-id="msgInfo.id"/>
    </div>
		<right-menu v-show="menu && rightMenu.show" :pos="rightMenu.pos" :items="menuItems"
			@close="rightMenu.show = false" @select="onSelectMenu"></right-menu>
		<chat-group-readed ref="chatGroupReadedBox" :msgInfo="msgInfo" :groupMembers="groupMembers"></chat-group-readed>
	</div>
</template>

<script>
	import HeadImage from "../common/HeadImage.vue";
	import RightMenu from '../common/RightMenu.vue';
	import ChatGroupReaded from './ChatGroupReaded.vue';
  import ChatShareFriend from '@/components/chat/ChatShareFriend.vue'
  import ChatFriendApply from '@/components/chat/ChatFriendApply.vue'
	export default {
		name: "messageItem",
		components: {
      ChatFriendApply,
      ChatShareFriend,
			HeadImage,
			RightMenu,
			ChatGroupReaded
		},
		props: {
			mode: {
				type: Number,
				default: 1
			},
			mine: {
				type: Boolean,
				required: true
			},
			headImage: {
				type: String,
				required: true
			},
      chatInfo: {
        type: Object,
        required: true
      },
			showName: {
				type: String,
				required: true
			},
			msgInfo: {
				type: Object,
				required: true
			},
			groupMembers: {
				type: Array
			},
			menu: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				audioPlayState: 'STOP',
				rightMenu: {
					show: false,
					pos: {
						x: 0,
						y: 0
					}
				}
			}
		},
		methods: {
			onSendFail() {
				this.$message.error("该文件已发送失败，目前不支持自动重新发送，建议手动重新发送")
			},
			showFullImageBox() {
				let imageUrl = JSON.parse(this.msgInfo.content).originUrl;
				if (imageUrl) {
					this.$store.commit('showFullImageBox', imageUrl);
				}
			},
			onPlayVoice() {
				if (!this.audio) {
					this.audio = new Audio();
				}
				this.audio.src = JSON.parse(this.msgInfo.content).url;
				this.audio.play();
				this.onPlayVoice = 'RUNNING';
			},
			showRightMenu(e) {
				this.rightMenu.pos = {
					x: e.x,
					y: e.y
				};
				this.rightMenu.show = "true";
			},
			onSelectMenu(item) {
				this.$emit(item.key.toLowerCase(), this.msgInfo);
			},
			onShowReadedBox() {
				let rect = this.$refs.chatMsgBox.getBoundingClientRect();
				this.$refs.chatGroupReadedBox.open(rect);
			}
		},
		computed: {
			loading() {
				return this.msgInfo.loadStatus && this.msgInfo.loadStatus === "loading";
			},
			loadFail() {
				return this.msgInfo.loadStatus && this.msgInfo.loadStatus === "fail";
			},
			data() {
				return JSON.parse(this.msgInfo.content)
			},
			fileSize() {
				let size = this.data.size;
				if (size > 1024 * 1024) {
					return Math.round(size / 1024 / 1024) + "M";
				}
				if (size > 1024) {
					return Math.round(size / 1024) + "KB";
				}
				return size + "B";
			},
			menuItems() {
				let items = [];
				items.push({
					key: 'DELETE',
					name: '删除',
					icon: 'el-icon-delete'
				});
				if (this.msgInfo.selfSend && this.msgInfo.id > 0) {
					items.push({
						key: 'RECALL',
						name: '撤回',
						icon: 'el-icon-refresh-left'
					});
				}
				return items;
			},
			isAction() {
				return this.$msgType.isAction(this.msgInfo.type);
			},
			isNormal() {
				const type = this.msgInfo.type;
				return this.$msgType.isNormal(type) || this.$msgType.isAction(type)
			}
		}
	}
</script>
